import React from "react";
import {Link} from "gatsby";
import {CogIcon, CodeIcon, UserCircleIcon, ViewGridAddIcon} from "@heroicons/react/outline";

const subNavigation = [
    { name: 'Dashboard', href: '/meet/dashboard', icon: UserCircleIcon },
    { name: 'Settings', href: '/meet/settings', icon: CogIcon },
    { name: 'Widget', href: '/meet/api', icon: CodeIcon },
    { name: 'Integrations', href: '/meet/integrations', icon: ViewGridAddIcon},
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


// eslint-disable-next-line react/prop-types
const MeetNavigation = () => {

    // eslint-disable-next-line react/prop-types
    const currentURL = typeof window !== "undefined" ? window.location.pathname : ""

    return (
        <nav className="space-y-1">
            {subNavigation.map((item) => (
                <Link
                    key={item.name}
                    to={item.href}
                    className={classNames(
                        item.href == currentURL
                            ? 'bg-gray-50 text-indigo-600 hover:bg-white'
                            : 'text-gray-900 hover:text-gray-900 hover:bg-gray-50',
                        'group rounded-md px-3 py-2 flex items-center text-sm font-medium'
                    )}
                    aria-current={item.href == currentURL ? 'page' : undefined}
                >
                    <item.icon
                        className={classNames(
                            item.href == currentURL ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-500',
                            'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                        )}
                        aria-hidden="true"
                    />
                    <span className="truncate">{item.name}</span>
                </Link>
            ))}
        </nav>
    )
}

export default MeetNavigation
