import React, {useState} from 'react'
import MeetNavigation from "../../components/meetNavigation";
import {useTranslation} from "react-i18next";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {ClipboardCopyIcon} from "@heroicons/react/solid";
import {Light as SyntaxHighlighter} from "react-syntax-highlighter";
import {dark} from "react-syntax-highlighter/dist/cjs/styles/hljs";

export default function Api() {

    const { t } = useTranslation()

    const [copiedJs, setCopiedJs] = useState(false)
    const [copiedDiv, setCopiedDiv] = useState(false)
    const [copiedCss, setCopiedCss] = useState(false)

    const ERROR_DISPLAY_TIME = 5000 // 5 seconds

    const jsTag = "<script defer=\"defer\" src=\"https://cdn.clickand.de/ca-meet-widget.min.js\"></script>"

    const divTag = "<div id=\"clickandmeet\" data-key=\"" + "..." + "\"/>"

    const cssTag = "<link href=\"https://cdn.clickand.de/ca-meet-widget.min.css\" rel=\"stylesheet\">"

    return (
        <main className="max-w-7xl mx-auto">
            <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
                <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
                    <MeetNavigation/>
                </aside>

                {/* details */}
                <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
                    <h1 className="text-2xl font-bold text-gray-900">Widget</h1>
                    <p>
                        Nachfolgende Code-Schnipsel kannst du in deine Webseite integrieren.
                    </p>
                    <div
                        className=" mt-5">

                        <p className="mt-2 mb-2 text-xs text-gray-400">
                            {t('meet.setup.widget.code.js')}
                        </p>

                        <div className="bg-gray-800 shadow-xl rounded-lg overflow-hidden">
                            <div id="header-buttons" className="py-3 px-4 flex">
                                <div className="rounded-full w-3 h-3 bg-red-500 mr-2"/>
                                <div className="rounded-full w-3 h-3 bg-yellow-500 mr-2"/>
                                <div className="rounded-full w-3 h-3 bg-green-500"/>
                                {copiedJs && (
                                    <div className="ml-auto pl-3">
                                        <div className="-mx-1.5 -my-1.5">
                                            <p className="h-5 w-5 text-gray-400 hover:text-gray-400 text-sm" aria-hidden="true">
                                                {t('label.copied')}
                                            </p>
                                        </div>
                                    </div>
                                )}
                                <div className="ml-auto pl-3 cursor-pointer">
                                    <CopyToClipboard
                                        onCopy={() => {
                                            setCopiedJs(true)
                                            setTimeout(function(){
                                                setCopiedJs(false)
                                            }.bind(this), ERROR_DISPLAY_TIME)
                                        }
                                        }
                                        text={jsTag}>
                                        <div className="-mx-1.5 -my-1.5">
                                            <ClipboardCopyIcon
                                                className="h-5 w-5 text-gray-200 hover:text-gray-400"
                                                aria-hidden="true" />
                                        </div>
                                    </CopyToClipboard>
                                </div>
                            </div>
                            <div id="code-area" className="pb-4 px-4 text-white">
                                <div className="text-xs">
                                    <SyntaxHighlighter
                                        lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}}
                                        wrapLines={true}
                                        language="html"
                                        style={dark}>
                                        {jsTag}
                                    </SyntaxHighlighter>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        className="mt-5">

                        <p className="mt-2 mb-2 text-xs text-gray-400">
                            {t('meet.setup.widget.code.div')}
                        </p>

                        <div className="bg-gray-800 shadow-xl rounded-lg overflow-hidden">
                            <div id="header-buttons" className="py-3 px-4 flex">
                                <div className="rounded-full w-3 h-3 bg-red-500 mr-2"/>
                                <div className="rounded-full w-3 h-3 bg-yellow-500 mr-2"/>
                                <div className="rounded-full w-3 h-3 bg-green-500"/>
                                {copiedDiv && (
                                    <div className="ml-auto pl-3">
                                        <div className="-mx-1.5 -my-1.5">
                                            <p className="h-5 w-5 text-gray-400 hover:text-gray-400 text-sm" aria-hidden="true">
                                                {t('label.copied')}
                                            </p>
                                        </div>
                                    </div>
                                )}
                                <div className="ml-auto pl-3 cursor-pointer">
                                    <CopyToClipboard
                                        onCopy={() => {
                                            setCopiedDiv(true)
                                            setTimeout(function(){
                                                setCopiedDiv(false)
                                            }.bind(this), ERROR_DISPLAY_TIME)
                                        }
                                        }
                                        text={divTag}>
                                        <div className="-mx-1.5 -my-1.5">
                                            <ClipboardCopyIcon
                                                className="h-5 w-5 text-gray-200 hover:text-gray-400"
                                                aria-hidden="true" />
                                        </div>
                                    </CopyToClipboard>
                                </div>
                            </div>
                            <div id="code-area" className="pb-4 px-4 text-white">
                                <div className="text-xs">
                                    <SyntaxHighlighter
                                        lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}}
                                        wrapLines={true}
                                        language="html"
                                        style={dark}>
                                        {divTag}
                                    </SyntaxHighlighter>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        className="mt-5">

                        <p className="mt-2 mb-2 text-xs text-gray-400">
                            {t('meet.setup.widget.code.css')}
                        </p>

                        <div className="bg-gray-800 shadow-xl rounded-lg overflow-hidden">
                            <div id="header-buttons" className="py-3 px-4 flex">
                                <div className="rounded-full w-3 h-3 bg-red-500 mr-2"/>
                                <div className="rounded-full w-3 h-3 bg-yellow-500 mr-2"/>
                                <div className="rounded-full w-3 h-3 bg-green-500"/>
                                {copiedCss && (
                                    <div className="ml-auto pl-3">
                                        <div className="-mx-1.5 -my-1.5">
                                            <p className="h-5 w-5 text-gray-400 hover:text-gray-400 text-sm" aria-hidden="true">
                                                {t('label.copied')}
                                            </p>
                                        </div>
                                    </div>
                                )}
                                <div className="ml-auto pl-3 cursor-pointer">
                                    <CopyToClipboard
                                        onCopy={() => {
                                            setCopiedCss(true)
                                            setTimeout(function(){
                                                setCopiedCss(false)
                                            }.bind(this), ERROR_DISPLAY_TIME)
                                        }
                                        }
                                        text={cssTag}>
                                        <div className="-mx-1.5 -my-1.5">
                                            <ClipboardCopyIcon
                                                className="h-5 w-5 text-gray-200 hover:text-gray-400"
                                                aria-hidden="true" />
                                        </div>
                                    </CopyToClipboard>
                                </div>
                            </div>
                            <div id="code-area" className="pb-4 px-4 text-white">
                                <div className="text-xs">
                                    <SyntaxHighlighter
                                        lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}}
                                        wrapLines={true}
                                        language="html"
                                        style={dark}>
                                        {cssTag}
                                    </SyntaxHighlighter>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </main>
    )
}
